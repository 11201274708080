<template>
	<div class='a-card-trip-direction'>
		<div class='a-card-trip-direction-header'>
			<v-icon :icon='`navigation/${$helpers.typeIcon(item.type)}`' :size='20' class='a-card-trip-direction-icon' />
			<p class='a-card-trip-title-1'>{{$t(`account.trips.${item.type}`)}}</p>
		</div>
		<div class='a-card-trip-direction-content'>
			<template v-if='item.type === "flight" || item.type === "charterFlight"'>
				<div class='a-card-trip-direction-flight'>
					<p class='a-card-trip-text-2'>{{item.itineraries[0].OriginLocName}} ({{item.itineraries[0].OriginLoc}})</p>
					<v-icon icon='air' size='14' class='a-card-trip-direction-flight-icon' />
					<p class='a-card-trip-text-2'>{{item.itineraries[0].DestinationLocName}} ({{item.itineraries[0].DestinationLoc}})</p>
				</div>
			</template>
			<template v-else>
				<p class='a-card-trip-text-2' v-if='title'>{{title}}</p>
				<p class='a-card-trip-text-2' v-if='destination'>{{destination}}</p>
			</template>

			<slot />
		</div>
	</div>
</template>

<script>
	export default {
		props: ['item'],
		computed: {
			title() {
				switch (this.item.type) {
					case 'hotel': {
						const night = this.$moment(this.item.dateTo).diff(this.$moment(this.item.dateFrom), "days");
						return `${night} ${this.$helpers.declOfNum(night, this.$t('account.declOfNum.nights'))}, ${this.item.name}`;
					}
					case 'transfer': {
						return `${this.$t('account.trips.transfer')} ${this.$t('account.to')} ${this.item.contract[0].destinationLocationName}`
					}
					default:
						return null;
				}
			},
			destination() {
				switch (this.item.type) {
					case 'hotel': {
						return `${this.item.destinationName}, ${this.item.countryName}`;
					}
					case 'transfer': {
						return this.item.contract[0].pickupLocationName;
					}
					default:
						return null;
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card-trip-direction {
		&-header {
			display: flex;
			align-items: center;
			.a-card-trip-direction-icon {
				margin-right: 10px;
			}
		}
		&-icon {
			flex-shrink: 0;
			color: $green-persian;
		}
		&-content {
			padding-left: 30px;
		}
		&-flight {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			&-icon {
				color: rgba($cyprus, .3);
				margin: 0 10px;
			}
		}
	}
</style>
