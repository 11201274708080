<template>
	<div class='a-card-trip-action'>
		<div class="a-card-trip-action-wrap">
			<div class='a-card-trip-action-price'>
				<div class="row">
					<div class="col">
						<p class='a-card-trip-title-2'>{{$t('account.trips.the_price_is_generally_for')}}</p>
						<template v-if='loading_price'>
							<div class='a-card-trip-action-loading'>
								<v-icon icon='loading' size='14' />
							</div>
						</template>
						<template v-else>
							<p class='a-card-trip-action-total'>{{this.$helpers.amount(order.price.total, order.price.currency)}}</p>
						</template>
						<template v-if='timer && updatedAt < 0'>
							<span class='a-card-trip-text-2' v-if='loading_price'>{{$t('account.trips.price_update')}}...</span>
							<span class='a-card-trip-link' @click='$emit("update-services")' v-else>{{$t('account.trips.update_price')}}</span>
						</template>
					</div>
					<div class="col-auto" v-if='timer && updatedAt > 0'>
						<p class='a-card-trip-action-timer orange--text'>
							<v-icon icon='timer' size='12' />
							<span>{{timeDiffAbbr(time)}}</span>
						</p>
					</div>
				</div>
			</div>

			<div class='a-card-trip-action-buttons'>
				<div class='a-card-trip-action-button delete'>
					<v-button block outline xxsmall color="green-persian" @click='dialog_delete = true'>{{$t('account.buttons.remove')}}</v-button>
				</div>
				<div class='a-card-trip-action-button next'>
					<v-button block xxsmall color="green-persian white--text" @click='pushOrder'>{{$t("account.buttons.continue")}}</v-button>
				</div>
			</div>

			<div class='a-card-trip-action-links'>
				<span class='a-card-trip-link' @click='copyOrder' :style='`${loading_btn_copy ? "pointer-events: none" : ""}`'>
					<div class='a-card-trip-action-loading' v-if='loading_btn_copy'>
						<v-icon icon='loading' size='14' />
					</div>
					<strong>{{$t("account.buttons.copy")}}</strong>
				</span>
				<span class='a-card-trip-link' @click='dialog_share = true'><strong>{{$t("account.buttons.share")}}</strong></span>
			</div>
		</div>

		<a-dialog-delete
			v-model='dialog_delete'
			:title='$t("account.messages.are_you_sure_you_want_to_delete_this_tour")'
			@close-dialog="dialog_delete = false"
			@action-dialog='removeFromSuitcase'
			:loading='loading_btn_delete'
		/>

		<a-dialog-share
			:order='order'
			v-model='dialog_share'
			@close-dialog="dialog_share = false"
		/>
	</div>
</template>

<script>
	import ADialogDelete from '@/components/account/dialogs/delete';
	import ADialogShare from '@/views/account/trips/dialogs/share';
	import { copyOrder } from '@/services/request';
	import { removeFromSuitcase } from '@/services/request';

	export default {
		data: () => ({
			time: null,
			timer: null,
			dialog_delete: false,
			dialog_share: false,
			loading_btn_copy: false,
			loading_btn_delete: false
		}),
		props: {
			order: Object,
			loading_price: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			updatedAt() {
				return this.$moment(this.$moment.utc(this.order.updatedAt).add(20, 'minutes')).diff(this.$moment.utc(), "seconds")
			}
		},
		components: {
			ADialogDelete,
			ADialogShare
		},
		created() {
			this.startTime();
		},
		methods: {
			pushOrder() {
				this.$router.push({
					name: "itinerary",
					params: {orderId: this.order.id, lang: this.$route.params.lang},
				})
			},
			copyOrder() {
				this.loading_btn_copy = true;

				copyOrder(this.order.id)
					.then(() => this.$emit("updateSuitcase"))
					.finally(() => this.loading_btn_copy = false)
			},
			removeFromSuitcase() {
				this.loading_btn_delete = true;

				removeFromSuitcase(this.order.id)
					.then(() => {
						this.$emit('removeFromSuitcase');
						this.dialog_delete = false;
					})
					.finally(() => this.loading_btn_delete = false)
			},
			startTime() {
				this.time = this.updatedAt * 1000;

				this.timer = setInterval(() => {
					if (this.time <= 0) clearInterval(this.timer);
					this.time = this.time - 1000;
				}, 1000);
			},
			timeDiffAbbr(timestamp) {
				let m = Math.floor((timestamp % 36e5) / 6e4);
				let s = Math.floor((timestamp % 6e4) / 1000);

				let minutes = m > 0 ? (m > 9 ? m : `0${m}`) : "00";
				let seconds = s > 0 ? (s > 9 ? s : `0${s}`) : "00";

				return `${minutes}:${seconds}`;
			}
		},
		watch: {
			updatedAt() {
				clearInterval(this.timer);
				this.startTime();
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card-trip-action {
		flex-shrink: 0;
		padding: 20px 30px;
		@include minw( $grid-breakpoints-sm ) {
			border-left: 1px solid rgba($black, .1);
		}
		@include maxw( $grid-breakpoints-sm - 1 ) {
			border-top: 1px solid rgba($black, .1);
		}
		&-wrap {
			@include minw( $grid-breakpoints-xs ) {
				width: 265px;
			}
		}
		&-price {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		&-total {
			font-size: 20px;
			font-weight: 700;
		}
		&-links {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				display: flex;
				justify-content: center;
			}
			.a-card-trip-link {
				&:not(:last-child) {
					margin-right: 30px;
				}
			}
		}
		&-buttons {
			display: flex;
			padding-top: 20px;
			padding-bottom: 30px;
			@include minw( $grid-breakpoints-xs ) {
				padding-top: 30px;
				padding-bottom: 20px;
			}
		}
		&-button {
			.app-btn {
				font-size: 15px;
			}
			&.delete {
				flex-grow: 1;
				margin-right: 10px;
				@include minw( $grid-breakpoints-xs ) {
					flex-grow: 0;
					width: 114px;
				}
				.app-btn {
					font-weight: 400;
				}
			}
			&.next {
				flex-grow: 1;
			}
		}
		&-loading {
			height: 24px;
			display: grid;
			align-items: center;
			color: $green-persian;
			.app-icon {
				animation: rotating .6s linear infinite;
				svg {
					fill: none;
					path {
						stroke: currentColor;
						stroke-width: 2px;
					}
					circle {
						stroke: currentColor;
						stroke-width: 2px;
						stroke-opacity: .25;
					}
				}
			}
		}
		&-timer {
			display: flex;
			align-items: center;
			.app-icon {
				flex-shrink: 0;
				margin-right: 5px;
			}
			span {
				font-size: 18px;
				font-weight: 700;
			}
		}
	}

	@keyframes rotating {
		from { transform: rotate(0deg); }
		to { transform: rotate(360deg); }
	}
</style>
