<template>
	<div class='a-card-trip-info'>
		<p class='a-card-trip-title-2'>{{title}}</p>
		<div class='a-card-trip-info-flex'>
			<span class='a-card-trip-text-1'>{{text ? text : $t("account.trips.not_specified")}}</span>
			<span class='a-card-trip-link' @click='link' v-if='link'>{{$t("account.buttons.change")}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['title', 'text', 'link']
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card-trip-info {
		&-flex {
			display: flex;
			align-items: baseline;
			flex-wrap: wrap;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				justify-content: space-between;
			}
			.a-card-trip-title-1,
			.a-card-trip-text-1 {
				margin-right: 10px;
			}
		}
		&-date {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin-top: 5px;
			.a-card-trip-title-2 {
				margin-right: 5px;
			}
		}
	}
</style>
