<template>
	<v-dialog :value='value' :width='1010' class-content='border-radius a-trip-dialog-share-wrap' @close-dialog="$emit('close-dialog')">
		<div class='a-trip-dialog-share-content'>
			<p class='a-trip-dialog-share-title'>{{$t("account.buttons.share")}}</p>

			<div class='a-trip-dialog-share-list'>
				<div class='a-trip-dialog-share-list-item' :class='{"active": tab === "view"}' @click='tab = "view"'>
					<v-icon icon='document' :size='12' />
					<span class='a-trip-dialog-share-list-item-text'>{{$t("account.buttons.view")}}</span>
				</div>
				<div class='a-trip-dialog-share-list-item' @click='generatePdf'>
					<v-icon class='loading' icon='loading' :size='12' v-if='loading_pdf' />
					<v-icon icon='download' :size='12' v-else />
					<span class='a-trip-dialog-share-list-item-text'>{{$t("account.buttons.download")}}</span>
				</div>
				<div class='a-trip-dialog-share-list-item' :class='{"active": tab === "send"}' @click='tab = "send"'>
					<v-icon icon='mail' :size='12' />
					<span class='a-trip-dialog-share-list-item-text'>{{$t("account.buttons.send")}}</span>
				</div>
				<div class='a-trip-dialog-share-list-item' :class='{"active": tab === "copy"}' @click='tab = "copy"'>
					<v-icon icon='copy' :size='12' />
					<span class='a-trip-dialog-share-list-item-text'>{{$t("account.comparison.copy_link")}}</span>
				</div>
			</div>

			<div class='a-trip-dialog-share-box'>
				<component :is="`ADialogShare` + this.tab.charAt(0).toUpperCase() + this.tab.slice(1)" :order='order' />
			</div>

			<a-pdf
				ref='app-pdf'
				:filename='order.name ? order.name : $t("account.trips.not_specified")'
				@update-loading='$event => loading_pdf = $event'
			>
				<template v-slot:content>
					<section class="pdf-item">
						<p class='a-pdf-text'>{{$t('account.trips.prices_are_for_reference_only')}} <span class='a-pdf-date'>{{$moment(order.updatedAt).format("DD/MM/YYYY HH:MM")}}</span></p>
						<p class='a-pdf-title'>{{order.name ? order.name : $t("account.trips.not_specified")}}</p>
					</section>

					<section class="pdf-item">
						<a-timeline :order='order' result hideImage v-if='order.items && order.items.length' />
					</section>

					<section class="pdf-item">
						<div class='a-pdf-total'>
							<div class="a-pdf-total-divider"></div>
							<div class='a-pdf-total-wrap'>
								<p class='a-pdf-total-text'>{{$t('account.trips.total')}}:</p>
								<p class='a-pdf-total-title'>{{$helpers.amount(order.price.total, order.price.currency)}}</p>
							</div>
						</div>
					</section>
				</template>
			</a-pdf>

		</div>
	</v-dialog>
</template>

<script>
	import ADialogShareView from '@/views/account/trips/dialogs/components/view';
	import ADialogShareSend from '@/views/account/trips/dialogs/components/send';
	import ADialogShareCopy from '@/views/account/trips/dialogs/components/copy';
	import APdf from '@/components/account/APdf';
	import ATimeline from '@/views/account/itinerary/timeline';

	export default {
		data: () => ({
			loading_pdf: false,
			tab: "view"
		}),
		props: ['value', 'order'],
		components: {
			ADialogShareView,
			ADialogShareSend,
			ADialogShareCopy,
			APdf,
			ATimeline
		},
		methods: {
			generatePdf() {
				this.$refs["app-pdf"].$refs.html2Pdf.generatePdf();
			}
		},
		watch: {
			value() {
				this.tab = "view";
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trip-dialog-share {
		&-wrap {
			padding: 0 !important;
		}
		&-content {
			padding: 40px 10px 100px;
			@include minw( $grid-breakpoints-xs ) {
				padding-left: 20px;
				padding-right: 20px;
			}
			@include minw( $grid-breakpoints-sm ) {
				padding: 35px 45px 75px;
			}
		}
		&-title {
			font-size: 22px;
			font-weight: 700;
			margin-bottom: 40px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-size: 20px;
				font-weight: 400;
				margin-bottom: 25px;
			}
		}
		&-list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 15px;
			color: $green-persian;
			&-item {
				display: flex;
				align-items: center;
				margin-bottom: 15px;
				cursor: pointer;
				&-text {
					font-size: 12px;
					font-weight: 700;
					margin-left: 5px;
				}
				.app-icon {
					flex-shrink: 0;
					&.loading {
						animation: rotating .6s linear infinite;
						svg {
							fill: none;
							path {
								stroke: currentColor;
								stroke-width: 2px;
							}
							circle {
								stroke: currentColor;
								stroke-width: 2px;
								stroke-opacity: .25;
							}
						}
					}
				}
				&:not(:last-child) {
					margin-right: 24px;
					@include minw( $grid-breakpoints-xs ) {
						margin-right: 35px;
					}
				}
				&.active {
					color: rgba($black, .6);
					cursor: default;
				}
			}
		}
		&-box {
			box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
		}
	}

	@keyframes rotating {
		from { transform: rotate(0deg); }
		to { transform: rotate(360deg); }
	}
</style>
