<template>
	<div class='a-trip-dialog-share-copy'>
		<div class="app-input">
			<div class="app-input__control">
				<div class="app-input__slot">
					<div class="app-input__field">
						<input v-model='link' readonly ref='inputCopy' />
					</div>
					<div class="app-input__append-inner mobile-hide" @click='copyToClipboard(link)'>
						<span class='app-link green--text a-trip-dialog-share-copy-link'>{{$t("account.buttons.copy")}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="mobile-show" @click='copyToClipboard'>
			<span class='app-link green--text a-trip-dialog-share-copy-link'>{{$t("account.buttons.copy")}}</span>
		</div>
	</div>
</template>

<script>
	import { copyToClipboard } from '@/helpers/copyToClipboard';

	export default {
		data: () => ({
			link: null
		}),
		props: ['order'],
		created() {
			this.link = location.origin + '/share-trip/' + this.order.id
		},
		methods: {
			copyToClipboard
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trip-dialog-share-copy {
		padding: 20px 10px 30px;
		@include minw( $grid-breakpoints-xs ) {
			padding-left: 20px;
			padding-right: 20px;
		}
		@include minw( $grid-breakpoints-sm ) {
			padding: 35px 40px;
		}
		&-link {
			font-size: 12px;
			font-weight: 400 !important;
			display: block;
			text-align: center;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin-top: 12px;
			}
		}
	}
</style>
