<template>
	<div class='a-trip-dialog-share-view'>
		<div class="row a-trip-dialog-share-view-row">
			<div class="col-auto">
				<span class='a-trip-dialog-share-view-logo' v-html="require('!html-loader!@/assets/logo-color.svg')" />
			</div>
			<div class="col">
				<p class='a-trip-dialog-share-view-title'>{{order.name ? order.name : $t("account.trips.not_specified")}}</p>
				<p class='a-trip-dialog-share-view-text'>{{$t('account.trips.prices_are_for_reference_only')}} <span class='a-trip-dialog-share-view-date'>{{$moment(order.updatedAt).format("DD/MM/YYYY HH:MM")}}</span></p>
			</div>
		</div>

		<a-timeline :order='order' result v-if='order.items && order.items.length' />

		<div class='a-trip-dialog-share-view-total'>
			<div class="a-trip-dialog-share-view-total-divider"></div>
			<div class='a-trip-dialog-share-view-total-wrap'>
				<p class='a-trip-dialog-share-view-total-text'>{{$t('account.trips.total')}}:</p>
				<p class='a-trip-dialog-share-view-total-title'>{{$helpers.amount(order.price.total, order.price.currency)}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import ATimeline from '@/views/account/itinerary/timeline';

	export default {
		props: ["order"],
		components: {
			ATimeline
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trip-dialog-share-view {
		padding: 15px 10px;
		@include minw( $grid-breakpoints-xs ) {
			padding-left: 20px;
			padding-right: 20px;
		}
		@include minw( $grid-breakpoints-xs ) {
			padding: 30px 35px 40px;
		}
		&-row {
			@include minw( $grid-breakpoints-xs ) {
				align-items: center;
			}
		}
		&-logo {
			width: 38px;
			height: 38px;
			display: block;
			@include minw( $grid-breakpoints-xs ) {
				width: 70px;
				height: 70px;
				margin: -10px;
			}
		}
		&-title {
			font-size: 14px;
			font-weight: 400;
			line-height: 1.5;
			text-align: right;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 18px;
			}
		}
		&-text {
			font-size: 8px;
			font-weight: 400;
			line-height: 1.5;
			color: rgba($black, .5);
			text-align: right;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 12px;
			}
		}
		&-date {
			font-size: 10px;
			font-weight: 400;
			line-height: 1.5;
			color: rgba($black, .8);
			text-align: right;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 13px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				display: block;
			}
		}
		&-total {
			&-divider {
				margin: 20px 0 10px;
				border-top: 1px solid rgba($black, .1);
				display: block;
				@include minw( $grid-breakpoints-xs ) {
					margin: 30px 0 30px 40px;
				}
			}
			&-wrap {
				display: flex;
				align-items: baseline;
				justify-content: flex-end;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					justify-content: space-between;
				}
			}
			&-title {
				font-size: 16px;
				font-weight: 700;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 22px;
				}
			}
			&-text {
				font-size: 12px;
				font-weight: 400;
				color: rgba($black, .5);
				@include minw( $grid-breakpoints-xs ) {
					font-size: 13px;
					margin-left: 5px;
					margin-right: 5px;
				}
			}
		}
	}
</style>
