<template>
	<div class='a-card-trip'>
		<a-card-header
			:order='order'
			@open-dialog-change='$emit("open-dialog-change", $event)'
		/>

		<div class='a-card-trip-wrap'>
			<div class='a-card-trip-content'>
				<a-card-item
					v-for="(item, i) in order.items" :key='i'
					:order='order'
					:item='item'
					:loading_price='loading_price'
					@updateSuitcase='$emit("updateSuitcase")'
				/>
			</div>
			<a-card-action
				:order='order'
				:loading_price='loading_price'
				@update-services='updateServices'
				@updateSuitcase='$emit("updateSuitcase")'
				@removeFromSuitcase='$emit("removeFromSuitcase")'
			/>
		</div>

		<div class='a-card-trip-footer'>
			<div class="a-card-trip-footer-row">
				<div class='a-card-trip-footer-col' v-if='order.mark'>
					<v-chip color='cyprus' rounded size='small' class='a-card-trip-footer-chip'>{{order.mark}}</v-chip>
				</div>
				<div class='a-card-trip-footer-col'>
					<span class='a-card-trip-link'
						@click='$emit("open-dialog-change", {orderId: order.id, value: order.mark, key: "mark"})'
					><strong>{{$t('account.trips.add_mark')}}</strong></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ACardHeader from '@/views/account/trips/card-header';
	import ACardItem from '@/views/account/trips/card-item';
	import ACardAction from '@/views/account/trips/card-action';
	import { updateServices } from '@/services/request';

	export default {
		data: () => ({
			loading_price: false
		}),
		props: ['order'],
		components: {
			ACardHeader,
			ACardItem,
			ACardAction
		},
		methods: {
			updateServices() {
				this.loading_price = true;

				updateServices(this.order.id)
					.then(() => this.$emit("updateSuitcase"))
					.finally(() => this.loading_price = false)
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card-trip {
		box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.2);
		border-radius: 10px;
		margin-bottom: 40px;
		@include minw( $grid-breakpoints-xs ) {
			margin-bottom: 30px;
		}
		&-title-1 {
			font-size: 16px;
			font-weight: 700;
			line-height: 1.5;
		}
		&-title-2 {
			font-size: 12px;
			font-weight: 400;
			line-height: 1.5;
			color: rgba($black, .5);
		}
		&-text-1 {
			font-size: 13px;
			font-weight: 400;
			line-height: 1.5;
		}
		&-text-2 {
			font-size: 12px;
			font-weight: 400;
			line-height: 1.5;
		}
		&-link {
			font-size: 12px;
			font-weight: 400;
			line-height: 1.5;
			color: $green;
			cursor: pointer;
			display: inline-block;
			position: relative;
			&:hover {
				color: $green-active !important;
			}
			.a-card-trip-action-loading {
				position: absolute;
				top: -2px;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba($white, .7);
				z-index: 1;
				display: grid;
				align-items: center;
				justify-content: center;
			}
		}
		&-wrap {
			@include minw( $grid-breakpoints-sm ) {
				display: flex;
			}
		}
		&-content {
			flex-grow: 1;
		}
		&-footer {
			padding: 12px 15px;
			border-top: 1px solid rgba($black, .1);
			@include minw( $grid-breakpoints-xs ) {
				padding: 12px 30px;
				min-height: 46px;
				display: grid;
			}
			&-row {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin: 0 -12px;
			}
			&-col {
				display: flex;
				padding: 0 12px;
			}
			&-chip {
				padding-top: 3px;
				padding-bottom: 3px;
			}
		}
	}
</style>
