<template>
	<v-dialog :value='value' :width='547' class='a-trip-dialog-change' class-content='border-radius a-trip-dialog-change-wrap' @close-dialog="$emit('close-dialog')">
		<div class='a-trip-dialog-change-content'>
			<p class='a-trip-dialog-change-title'>{{dialog_change.title}}</p>
			<div class="row a-trip-dialog-change-row">
				<div class="col">
					<v-text-field
						:placeholder="dialog_change.placeholder"
						name='input'
						v-model='input'
						hide-details
						class='a-trip-dialog-change-input'
						:mask='dialog_change.mask'
					/>
				</div>
				<div class="col-auto">
					<v-button block xxsmall :color='`green-persian white--text`' class='a-trip-dialog-change-btn' @click="dialog_change.action(input)" :loading='dialog_change.loading_btn'>{{$t("account.buttons.apply")}}</v-button>
				</div>
			</div>
		</div>
	</v-dialog>
</template>

<script>
	export default {
		data: () => ({
			input: ''
		}),
		props: {
			value: Boolean,
			dialog_change: Object
		},
		watch: {
			"value"() {
				this.input = this.value ? this.dialog_change.initValue : "";
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trip-dialog-change {
		.app-popup {
			overflow-y: auto;
		}
		&-wrap {
			padding: 0 !important;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				max-width: 100% !important;
				border-radius: 0 !important;
				display: grid;
				align-items: center;
			}
		}
		&-content {
			padding: 40px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				padding: 40px 20px;
			}
		}
		&-row {
			@include minw( $grid-breakpoints-xs ) {
				align-items: center;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex-direction: column;
			}
		}
		&-title {
			font-size: 22px;
			font-weight: 700;
			margin-bottom: 30px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-size: 18px;
				margin-bottom: 15px;
			}
		}
		&-input {
			margin-bottom: 30px;
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 0;
			}
			&__slot {
				min-height: 47px;
			}
		}
		&-btn {
			min-width: 128px !important;
		}
	}
</style>
